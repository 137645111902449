
import { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { MainContext, useContext } from "../Context/MainContext";
function HeaderNavComponentGad() {
    const [language, setLanguage] = useState("English");
    const { setLan, lan } = useContext(MainContext);

    const UzbekBtn = () => {
        setLan('uz');
        setLanguage("O'zbekcha");
    }
    const EnglishBtn = () => {
        setLan("en");
        setLanguage("English");
    }
    const RussianBtn = () => {
        setLan("ru");
        setLanguage("Русский");
    }
    const TurkishBtn = () => {
        setLan("tr");
        setLanguage("Türkçe");
    }

    if (lan === 'en') {
        return (
            <div className="paddingThero HeaderNav">
                <Container className="marginThero">
                    <Row>
                        <Col className="col-9">
                            <span className="logo2"><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span> {/*bu logotipdir*/}
                        </Col>
                        <Col className="col-3">

                            <nav class="navbar navbar-dark ">
                                <button class="navbar-toggler NavBtnGad " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="black" class="bi bi-list" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                                    </svg> {/* bu button navigationni ochish uchun foydalaniladi */}
                                </button>
                                <div class="offcanvas offcanvas-end " tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                                    <Row>
                                        <Col className="col-10">
                                            <span className="logo2"><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span>
                                        </Col>
                                        <Col className="col-2">
                                            <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button> {/* bu button navigationni yopadigan x tugmasidir */}
                                        </Col>
                                    </Row>
                                    <br /><br />
                                    <Row>
                                        <ul className="ulListNavGad ">
                                            <li className="padding1">
                                                <div class="container-fluid">
                                                    <form class="d-flex" role="search">
                                                        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                                        <button class="btn btn-outline-success" type="submit">Search</button>
                                                    </form>
                                                </div>
                                            </li>
                                            <li className="padding1"> <a class="btn textAnim" href="#ourEduaction-en"><b>Products</b></a> </li>
                                            <li className="padding1 "><a class="btn textAnim" href="/education"><b>Our Education</b></a></li>
                                            <li className="padding1"><a class="btn textAnim" href="#ourTeam-en"><b>Our Team</b></a></li>
                                            <li className="padding1"><a class="btn textAnim" href="#AboutUs-en"><b>About us</b></a></li>
                                            <li className="padding1">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header">
                                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <a class="btn textAnim" href="#AboutUs-en"><b>{language}</b></a>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            <ul class="">
                                                                <li class="dropdown-item" onClick={EnglishBtn}>English</li>
                                                                <li class="dropdown-item" onClick={UzbekBtn}>O'zbekcha</li>
                                                                <li class="dropdown-item" onClick={TurkishBtn}>Türkçe</li>
                                                                <li class="dropdown-item" onClick={RussianBtn}>Русский</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Row>
                                </div>

                            </nav>

                        </Col>
                    </Row>
                </Container>
            </div >
        );
    } if (lan === "ru") {

        return (
            <div className="paddingThero HeaderNav">
                <Container className="marginThero">
                    <Row>
                        <Col className="col-9">
                            <span className="logo2"><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span> {/*bu logotipdir*/}
                        </Col>
                        <Col className="col-3">

                            <nav class="navbar navbar-dark ">
                                <button class="navbar-toggler NavBtnGad " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="black" class="bi bi-list" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                                    </svg> {/* bu button navigationni ochish uchun foydalaniladi */}
                                </button>
                                <div class="offcanvas offcanvas-end " tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                                    <Row>
                                        <Col className="col-10">
                                            <span className="logo2"><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span>
                                        </Col>
                                        <Col className="col-2">
                                            <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button> {/* bu button navigationni yopadigan x tugmasidir */}
                                        </Col>
                                    </Row>
                                    <br /><br />
                                    <Row>
                                        <ul className="ulListNavGad ">
                                            <li className="padding1">
                                                <div class="container-fluid">
                                                    <form class="d-flex" role="search">
                                                        <input class="form-control me-2" type="search" placeholder="Поиск" aria-label="Search" />
                                                        <button class="btn btn-outline-success" type="submit">Поиск</button>
                                                    </form>
                                                </div>
                                            </li>
                                            <li className="padding1"> <a class="btn textAnim" href="#ourEduaction-ru"><b>Продукты</b></a> </li>
                                            <li className="padding1 "><a class="btn textAnim" href="/education"><b>Наше образование</b></a></li>
                                            <li className="padding1"><a class="btn textAnim" href="#ourTeam-ru"><b>Наша команда</b></a></li>
                                            <li className="padding1"><a class="btn textAnim" href="#AboutUs-ru"><b>О нас</b></a></li>
                                            <li className="padding1">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header">
                                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <a class="btn textAnim" href="#AboutUs-ru"><b>{language}</b></a>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            <ul class="">
                                                                <li class="dropdown-item" onClick={EnglishBtn}>English</li>
                                                                <li class="dropdown-item" onClick={UzbekBtn}>O'zbekcha</li>
                                                                <li class="dropdown-item" onClick={TurkishBtn}>Türkçe</li>
                                                                <li class="dropdown-item" onClick={RussianBtn}>Русский</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Row>
                                </div>

                            </nav>

                        </Col>

                    </Row>
                </Container>

            </div >
        );

    } if (lan === "tr") {

        return (
            <div className="paddingThero HeaderNav">
                <Container className="marginThero">
                    <Row>
                        <Col className="col-9">
                            <span className="logo2"><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span> {/*bu logotipdir*/}
                        </Col>
                        <Col className="col-3">

                            <nav class="navbar navbar-dark ">
                                <button class="navbar-toggler NavBtnGad " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="black" class="bi bi-list" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                                    </svg> {/* bu button navigationni ochish uchun foydalaniladi */}
                                </button>
                                <div class="offcanvas offcanvas-end " tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                                    <Row>
                                        <Col className="col-10">
                                            <span className="logo2"><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span>
                                        </Col>
                                        <Col className="col-2">
                                            <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button> {/* bu button navigationni yopadigan x tugmasidir */}
                                        </Col>
                                    </Row>
                                    <br /><br />
                                    <Row>
                                        <ul className="ulListNavGad ">
                                            <li className="padding1">
                                                <div class="container-fluid">
                                                    <form class="d-flex" role="search">
                                                        <input class="form-control me-2" type="search" placeholder="Aramak" aria-label="Search" />
                                                        <button class="btn btn-outline-success" type="submit">Aramak</button>
                                                    </form>
                                                </div>
                                            </li>
                                            <li className="padding1"> <a class="btn textAnim" href="#ourEduaction-tr"><b>Ürünler</b></a> </li>
                                            <li className="padding1 "><a class="btn textAnim" href="/education"><b>Eğitimimiz</b></a></li>
                                            <li className="padding1"><a class="btn textAnim" href="#ourTeam-tr"><b>Bizim takım</b></a></li>
                                            <li className="padding1"><a class="btn textAnim" href="#AboutUs-tr"><b>Hakkımızda</b></a></li>
                                            <li className="padding1">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header">
                                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <a class="btn textAnim" href="#AboutUs-tr"><b>{language}</b></a>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            <ul class="">
                                                                <li class="dropdown-item" onClick={EnglishBtn}>English</li>
                                                                <li class="dropdown-item" onClick={UzbekBtn}>O'zbekcha</li>
                                                                <li class="dropdown-item" onClick={TurkishBtn}>Türkçe</li>
                                                                <li class="dropdown-item" onClick={RussianBtn}>Русский</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Row>
                                </div>

                            </nav>

                        </Col>

                    </Row>
                </Container>

            </div >
        );


    } else {


        return (
            <div className="paddingThero HeaderNav">
                <Container className="marginThero">
                    <Row>
                        <Col className="col-9">
                            <span className="logo2"><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span> {/*bu logotipdir*/}
                        </Col>
                        <Col className="col-3">

                            <nav class="navbar navbar-dark ">
                                <button class="navbar-toggler NavBtnGad " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="black" class="bi bi-list" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                                    </svg> {/* bu button navigationni ochish uchun foydalaniladi */}
                                </button>
                                <div class="offcanvas offcanvas-end " tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                                    <Row>
                                        <Col className="col-10">
                                            <span className="logo2"><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span>
                                        </Col>
                                        <Col className="col-2">
                                            <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button> {/* bu button navigationni yopadigan x tugmasidir */}
                                        </Col>
                                    </Row>
                                    <br /><br />
                                    <Row>
                                        <ul className="ulListNavGad ">
                                            <li className="padding1">
                                                <div class="container-fluid">
                                                    <form class="d-flex" role="search">
                                                        <input class="form-control me-2" type="search" placeholder="Qidirmoq" aria-label="Search" />
                                                        <button class="btn btn-outline-success" type="submit">Qidirmoq</button>
                                                    </form>
                                                </div>
                                            </li>
                                            <li className="padding1"> <a class="btn textAnim" href="#ourEduaction-uz"><b>Mahsulotlar</b></a> </li>
                                            <li className="padding1 "><a class="btn textAnim" href="/education"><b>Bizning ta'lim</b></a></li>
                                            <li className="padding1"><a class="btn textAnim" href="#ourTeam-uz"><b>Bizning Jamoamiz</b></a></li>
                                            <li className="padding1"><a class="btn textAnim" href="#AboutUs-uz"><b>Biz haqimizda</b></a></li>
                                            <li className="padding1">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header">
                                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <a class="btn textAnim" href="#AboutUs-uz"><b>{language}</b></a>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            <ul class="">
                                                                <li class="dropdown-item" onClick={EnglishBtn}>English</li>
                                                                <li class="dropdown-item" onClick={UzbekBtn}>O'zbekcha</li>
                                                                <li class="dropdown-item" onClick={TurkishBtn}>Türkçe</li>
                                                                <li class="dropdown-item" onClick={RussianBtn}>Русский</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Row>
                                </div>

                            </nav>

                        </Col>

                    </Row>
                </Container>

            </div >
        );


    }

}

export default HeaderNavComponentGad;
