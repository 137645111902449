import { Dropdown } from "bootstrap";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { MainContext, useContext } from "../Context/MainContext";
function HeaderNavComponent() {
    const [language, setLanguage] = useState("English");
    const { setLan, lan } = useContext(MainContext);
   

    const UzbekBtn = () => {
        setLan('uz');
        setLanguage("O'zbekcha");
    }
    const EnglishBtn = () => {
        setLan("en");
        setLanguage("English");
    }
    const RussianBtn = () => {
        setLan("ru");
        setLanguage("Русский");
    }
    const TurkishBtn = () => {
        setLan("tr");
        setLanguage("Türkçe");
    }

    

    if (lan === 'en') {
        return (
            <div className="paddingThero HeaderNav">
                <Container className="marginThero">
                    <Row>
                        <Col className="headerCols col-4">
                            <span className="logo2 "><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span>
                        </Col>
                        <Col className="col-5">
                            <ul className="nav ">
                                <li className="padding1">
                                    <button class="btn  dropdown-toggle textAnim" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <b>Products</b>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="/education">Learn programming with our team</a></li>
                                        <li><a class="dropdown-item" href="/AboutUsPage">Make Web-sites</a></li>
                                        <li><a class="dropdown-item" href="/AboutUsPage">Make Apps</a></li>
                                        <li><a class="dropdown-item" href="#">Work with us</a></li>
                                    </ul>
                                </li>
                                <li className="padding1 "><a class="btn textAnim" href="/education"><b>Our Education</b></a></li>
                                <li className="padding1"><a class="btn textAnim" href="#ourTeam-en"><b>Our Team</b></a></li>
                                <li className="padding1"><a class="btn textAnim" href="/AboutUsPage/"><b>About us</b></a></li>
                                <li className="padding1 "><button type="button" class="btn textAnim" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg></button>
                                </li>
                                
    
                            </ul>
                        </Col>
                        <Col className="col-3">
                            <button class="btn  dropdown-toggle headerCol-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <b>{language}</b>
                            </button>
                            <ul class="dropdown-menu">
                                <li class="dropdown-item" onClick={EnglishBtn}>English</li>
                                <li class="dropdown-item" onClick={UzbekBtn}>O'zbekcha</li>
                                <li class="dropdown-item" onClick={TurkishBtn}>Türkçe</li>
                                <li class="dropdown-item" onClick={RussianBtn}>Русский</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    } if (lan === "ru") {
        return (
            <div className="paddingThero HeaderNav">
                <Container className="marginThero">
                    <Row>
                        <Col className="headerCols col-3">
                            <span className="logo2 "><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span>
                        </Col>
                        <Col className="col-7">
                            <ul className="nav ">
                                <li className="padding1">
                                    <button class="btn  dropdown-toggle textAnim" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <b>Продукты</b>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="/education">Изучайте программирование вместе с нашей командой</a></li>
                                        <li><a class="dropdown-item" href="/AboutUsPage">Делайте веб-сайты</a></li>
                                        <li><a class="dropdown-item" href="/AboutUsPage">Создавать приложения</a></li>
                                        <li><a class="dropdown-item" href="#">Работайте с нами</a></li>
                                    </ul>
                                </li>
                                <li className="padding1 "><a class="btn textAnim" href="/education"><b>Наше образование</b></a></li>
                                <li className="padding1"><a class="btn textAnim" href="#ourTeam-ru"><b>Наша команда</b></a></li>
                                <li className="padding1"><a class="btn textAnim" href="/AboutUsPage"><b>О нас</b></a></li>
                                <li className="padding1"><button type="button" class="btn textAnim"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg></button></li>
                            </ul>
                        </Col>
                        <Col className="col-2">
                            <button class="btn  dropdown-toggle headerCol-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <b>{language}</b>
                            </button>
                            <ul class="dropdown-menu">
                                <li class="dropdown-item" onClick={EnglishBtn}>English</li>
                                <li class="dropdown-item" onClick={UzbekBtn}>O'zbekcha</li>
                                <li class="dropdown-item" onClick={TurkishBtn}>Türkçe</li>
                                <li class="dropdown-item" onClick={RussianBtn}>Русский</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    } if (lan === "tr") {
        return (
            <div className="paddingThero HeaderNav">
                <Container className="marginThero">
                    <Row>
                        <Col className="headerCols col-4">
                            <span className="logo2 "><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span>
                        </Col>
                        <Col className="col-5">
                            <ul className="nav ">
                                <li className="padding1">
                                    <button class="btn  dropdown-toggle textAnim" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <b>Ürünler</b>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="/education">Ekibimizle programlamayı öğrenin</a></li>
                                        <li><a class="dropdown-item" href="/AboutUsPage">Web siteleri yapın</a></li>
                                        <li><a class="dropdown-item" href="/AboutUsPage">Uygulama Yap</a></li>
                                        <li><a class="dropdown-item" href="#">Bizimle çalışın</a></li>
                                    </ul>
                                </li>
                                <li className="padding1 "><a class="btn textAnim" href="/education"><b>Eğitimimiz</b></a></li>
                                <li className="padding1"><a class="btn textAnim" href="#ourTeam-tr"><b>Bizim takım</b></a></li>
                                <li className="padding1"><a class="btn textAnim" href="/AboutUsPage"><b>Hakkımızda</b></a></li>
                                <li className="padding1"><button type="button" class="btn textAnim"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg></button></li>
                            </ul>
                        </Col>
                        <Col className="col-3">
                            <button class="btn  dropdown-toggle headerCol-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <b>{language}</b>
                            </button>
                            <ul class="dropdown-menu">
                                <li class="dropdown-item" onClick={EnglishBtn}>English</li>
                                <li class="dropdown-item" onClick={UzbekBtn}>O'zbekcha</li>
                                <li class="dropdown-item" onClick={TurkishBtn}>Türkçe</li>
                                <li class="dropdown-item" onClick={RussianBtn}>Русский</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    } else {
        return (
            <div className="paddingThero HeaderNav">
                <Container className="marginThero">
                    <Row>
                        <Col className="headerCols col-4">
                            <span className="logo2 "><b>&lt;</b></span><span className="logo1 "><b>We</b></span><span className="logo3 "><b>Started</b></span><span className="logo2 "><b>&gt;</b></span>
                        </Col>
                        <Col className="col-6">
                            <ul className="nav ">
                                <li className="padding1">
                                    <button class="btn  dropdown-toggle textAnim" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <b>Mahsulotlar</b>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="/education">Bizning jamoamiz bilan dasturlashni o'rganing</a></li>
                                        <li><a class="dropdown-item" href="/AboutUsPage">Veb-saytlar yaratish</a></li>
                                        <li><a class="dropdown-item" href="/AboutUsPage">Ilovalar yaratish</a></li>
                                        <li><a class="dropdown-item" href="#">Biz bilan ishlang</a></li>
                                    </ul>
                                </li>
                                <li className="padding1 "><a class="btn textAnim" href="/education"><b>Bizning ta'lim</b></a></li>
                                <li className="padding1"><a class="btn textAnim" href="#ourTeam-uz"><b>Bizning Jamoamiz</b></a></li>
                                <li className="padding1"><a class="btn textAnim" href="/AboutUsPage"><b>Biz haqimizda</b></a></li>
                                <li className="padding1"><button type="button" class="btn textAnim"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg></button></li>
                            </ul>
                        </Col>
                        <Col className="col-2">
                            <button class="btn  dropdown-toggle headerCol-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <b>{language}</b>
                            </button>
                            <ul class="dropdown-menu">
                                <li class="dropdown-item" onClick={EnglishBtn}>English</li>
                                <li class="dropdown-item" onClick={UzbekBtn}>O'zbekcha</li>
                                <li class="dropdown-item" onClick={TurkishBtn}>Türkçe</li>
                                <li class="dropdown-item" onClick={RussianBtn}>Русский</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }

}

export default HeaderNavComponent;
