import HeaderComponent from "../Components/HeaderComponent";
import HeaderComponentGad from "../GadgetComponents/HeaderComGad";
import FooterComponent from "../Components/FooterComponent";
import { Container, Row, Col, Form } from "reactstrap";
import { MainContext, useContext } from "../Context/MainContext";
import img1 from "../backgrounds/yazilimDanismanlik.jpeg";
import img2 from "../backgrounds/egitim.jpg";
import img3 from "../backgrounds/yazilimDestek.jpg";
import img4 from "../backgrounds/webGelsitirme.jpg";
import img5 from "../backgrounds/androidgelistirme.jpg";
import { useState } from "react";

function AboutUsPage() {

    const [form_email, setformemail]= useState("");
    const [form_number, setformnumber]= useState("");
    const [form_autor, setformeauthor]= useState("");
    const [form_manzil, setformmanzil]= useState("");
    const [form_izoh, setformizoh]= useState("");

    const submitEvent = () => {

        console.log('Form verileri:', form_autor + form_email +form_izoh +form_number +form_manzil);
      };

    const { lan } = useContext(MainContext);
    if (lan === "en") {
        return (
            <div>
                <Container className="marginThero body">
                    <Row className="headerControl">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                        <HeaderComponent></HeaderComponent>
                    </Row>
                    <Row className="hederCon">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                        <HeaderComponentGad></HeaderComponentGad>
                    </Row>
                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img1} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Technical support</h1>
                            <p class="text-start fs-5 text-light padding2">Would you like to get technical assistance to become more experienced and effective in software fields?</p>
                            <p class="text-start fs-5 text-light padding2">Are you looking to improve your software development process with expert technical assistance from our team?</p>
                            <p class="text-start fs-5 text-light padding2">How about meeting our expert technical support team to solve your software problems quickly and effectively?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Contact</a></p>
                        </Col>
                    </Row>
                    <Row>

                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Education</h1>
                            <p class="text-start fs-5 text-light padding2">How about working with our expert trainers to improve your software skills and take your career one step further?</p>
                            <p class="text-start fs-5 text-light padding2">Would you like to be trained in software fields to make you more successful in your job?</p>
                            <p class="text-start fs-5 text-light padding2">Do you want to enhance your employees' skills and knowledge in software development through our specialized training programs?</p><br />
                            <p className=" text-center fs-3"><a href="/education" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Contact</a></p>
                        </Col>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img2} class="img-fluid" alt="..." /></Col>
                    </Row>
                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img3} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Consultancy</h1>
                            <p class="text-start fs-5 text-light padding2">Would you like to increase efficiency and optimize costs in your business by taking advantage of our software consultancy service?</p>
                            <p class="text-start fs-5 text-light padding2">How about taking advantage of our professional software consultancy to maximize the technological potential of your business and gain competitive advantage?</p>
                            <p class="text-start fs-5 text-light padding2">What software issues are you encountering and what kind of help do you need?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Contact</a></p>
                        </Col>
                    </Row>
                    <Row>

                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Website</h1>
                            <p class="text-start fs-5 text-light padding2">Would you like to provide better service to your customers by developing web sites?</p>
                            <p class="text-start fs-5 text-light padding2">Would you like to have a web application that will improve your customers' experience and highlight your brand?</p>
                            <p class="text-start fs-5 text-light padding2">How about developing a customized, user-friendly and innovative web application for your business?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Contact</a></p>
                        </Col>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img4} class="img-fluid" alt="..." /></Col>
                    </Row>

                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img5} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Apps</h1>
                            <p class="text-start fs-5 text-light padding2">Want to expand your reach and engage with customers on mobile platforms through a personalized Android application developed by our team?</p>
                            <p class="text-start fs-5 text-light padding2">Would you like to provide better service to your customers by developing Android applications?</p>
                            <p class="text-start fs-5 text-light padding2">How about working with us to take your place in the mobile world and offer your users a unique Android application?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Contact</a></p>
                        </Col>
                    </Row>
                    <Row id="ContactUs" className="marginCart1">
                        <Col className="col-12 col-sm-12 col-lg-6">
                            <h1 className="text-center">Contact us</h1>
                            <Row className="marginCart1">
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="name@example.com" />
                                        <label for="floatingInput">Email address</label>
                                    </div>
                                </Col>
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="Davronbek" />
                                        <label for="floatingInput">Your name</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="Uzbekistan Fergana" />
                                        <label for="floatingInput">Adress</label>
                                    </div>
                                </Col>
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="+998 99 000 00 00" />
                                        <label for="floatingInput">Phone number</label>
                                    </div>
                                </Col>
                            </Row>
                            <div class="form-floating mb-3">
                                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>
                                <label for="floatingTextarea2">Comments</label>
                            </div>
                            <input class="btn btn-primary btnOurTeam" type="submit" value="Submit" />

                        </Col>
                        <Col className="marginCart1 col-12 col-sm-12 col-lg-6">
                            <div class="card text-center border-success">
                                <div class="card-header">
                                    <h5 class="card-title"> Meet us</h5>
                                </div>
                                <div class="card-body text-start">
                                    <h5 class="card-title">Call us</h5>
                                    <p class="card-text">+90 534 295 36 67</p>
                                    <h5 class="card-title">Location</h5>
                                    <p class="card-text">Turkey-Ankara</p>
                                    <h5 class="card-title">Business hour</h5>
                                    <p class="card-text">+3 09:00-12:00 and 14:00-16:00</p>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row className="marginCart1">
                        <FooterComponent></FooterComponent>
                    </Row>

                </Container>
            </div>
        );
    } if (lan === "ru") {
        return (
            <div>
                <Container className="marginThero body">
                    <Row className="headerControl">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                        <HeaderComponent></HeaderComponent>
                    </Row>
                    <Row className="hederCon">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                        <HeaderComponentGad></HeaderComponentGad>
                    </Row>
                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img1} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Техническая поддержка</h1>
                            <p class="text-start fs-5 text-light padding2">Хотели бы вы получить техническую помощь, чтобы стать более опытным и эффективным в области программного обеспечения?</p>
                            <p class="text-start fs-5 text-light padding2">Вы хотите улучшить свой процесс разработки программного обеспечения с помощью экспертной технической помощи нашей команды?</p>
                            <p class="text-start fs-5 text-light padding2">Как насчет встречи с нашей экспертной командой технической поддержки, чтобы быстро и эффективно решить ваши проблемы с программным обеспечением?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Контакт</a></p>
                        </Col>
                    </Row>
                    <Row>

                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Образование</h1>
                            <p class="text-start fs-5 text-light padding2">Как насчет того, чтобы поработать с нашими опытными инструкторами, чтобы улучшить свои навыки работы с программным обеспечением и продвинуть свою карьеру еще на один шаг вперед?</p>
                            <p class="text-start fs-5 text-light padding2">Хотели бы вы пройти обучение в области программного обеспечения, чтобы добиться большего успеха в своей работе?</p>
                            <p class="text-start fs-5 text-light padding2">Хотите повысить навыки и знания своих сотрудников в области разработки программного обеспечения с помощью наших специализированных программ обучения?</p><br />
                            <p className=" text-center fs-3"><a href="/education" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Контакт</a></p>
                        </Col>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img2} class="img-fluid" alt="..." /></Col>
                    </Row>
                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img3} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Консультирование</h1>
                            <p class="text-start fs-5 text-light padding2">Хотели бы вы повысить эффективность и оптимизировать затраты в своем бизнесе, воспользовавшись нашими консультационными услугами по программному обеспечению?</p>
                            <p class="text-start fs-5 text-light padding2">Как насчет того, чтобы воспользоваться нашими профессиональными консультациями по программному обеспечению, чтобы максимизировать технологический потенциал вашего бизнеса и получить конкурентное преимущество?</p>
                            <p class="text-start fs-5 text-light padding2">С какими проблемами программного обеспечения вы столкнулись и какая помощь вам нужна?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Контакт</a></p>
                        </Col>
                    </Row>
                    <Row>

                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Веб-сайт</h1>
                            <p class="text-start fs-5 text-light padding2">Хотели бы вы улучшить обслуживание своих клиентов путем разработки веб-сайтов?</p>
                            <p class="text-start fs-5 text-light padding2">Хотели бы вы иметь веб-приложение, которое улучшит качество обслуживания ваших клиентов и подчеркнет ваш бренд?</p>
                            <p class="text-start fs-5 text-light padding2">Как насчет разработки индивидуального, удобного и инновационного веб-приложения для вашего бизнеса?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Контакт</a></p>
                        </Col>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img4} class="img-fluid" alt="..." /></Col>
                    </Row>

                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img5} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Программы</h1>
                            <p class="text-start fs-5 text-light padding2">Хотите расширить свой охват и взаимодействовать с клиентами на мобильных платформах с помощью персонализированного приложения для Android, разработанного нашей командой?</p>
                            <p class="text-start fs-5 text-light padding2">Хотели бы вы улучшить обслуживание своих клиентов, разрабатывая приложения для Android?</p>
                            <p class="text-start fs-5 text-light padding2">Как насчет сотрудничества с нами, чтобы занять свое место в мобильном мире и предложить своим пользователям уникальное приложение для Android?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Контакт</a></p>
                        </Col>
                    </Row>
                    <Row id="ContactUs" className="marginCart1">
                        <Col className="col-12 col-sm-12 col-lg-6">
                            <h1 className="text-center">Связаться с нами</h1>
                            <Row className="marginCart1">
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="name@example.com" />
                                        <label for="floatingInput">Электронная почта</label>
                                    </div>
                                </Col>
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="Davronbek" />
                                        <label for="floatingInput">Ваше имя</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="Uzbekistan Fergana" />
                                        <label for="floatingInput">Адрес</label>
                                    </div>
                                </Col>
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="+998 99 000 00 00" />
                                        <label for="floatingInput">Номер телефона</label>
                                    </div>
                                </Col>
                            </Row>
                            <div class="form-floating mb-3">
                                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>
                                <label for="floatingTextarea2">Комментарии</label>
                            </div>
                            <input class="btn btn-primary btnOurTeam" type="submit" value="Submit" />

                        </Col>
                        <Col className="marginCart1 col-12 col-sm-12 col-lg-6">
                            <div class="card text-center border-success">
                                <div class="card-header">
                                    <h5 class="card-title"> Встреть нас</h5>
                                </div>
                                <div class="card-body text-start">
                                    <h5 class="card-title">Позвоните нам</h5>
                                    <p class="card-text">+90 534 295 36 67</p>
                                    <h5 class="card-title">Развязка</h5>
                                    <p class="card-text">Турция-Анкара</p>
                                    <h5 class="card-title">Рабочий час</h5>
                                    <p class="card-text">+3 09:00-12:00 and 14:00-16:00</p>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row className="marginCart1">
                        <FooterComponent></FooterComponent>
                    </Row>

                </Container>
            </div>
        );
    } if (lan === "tr") {
        return (
            <div>
                <Container className="marginThero body">
                    <Row className="headerControl">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                        <HeaderComponent></HeaderComponent>
                    </Row>
                    <Row className="hederCon">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                        <HeaderComponentGad></HeaderComponentGad>
                    </Row>
                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img1} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Teknik Destek</h1>
                            <p class="text-start fs-5 text-light padding2">Yazılım alanlarında daha deneyimli ve etkili olabilmek için teknik yardım almak ister misiniz?</p>
                            <p class="text-start fs-5 text-light padding2">Ekibimizin uzman teknik desteğiyle yazılım geliştirme sürecinizi geliştirmek mi istiyorsunuz?</p>
                            <p class="text-start fs-5 text-light padding2">Yazılım sorunlarınızı hızlı ve etkili bir şekilde çözmek için uzman teknik destek ekibimizle tanışmaya ne dersiniz?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Bize Ulaşın</a></p>
                        </Col>
                    </Row>
                    <Row>

                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Eğitim</h1>
                            <p class="text-start fs-5 text-light padding2">Yazılım becerilerinizi geliştirmek ve kariyerinizi bir adım öteye taşımak için uzman eğitmenlerimizle çalışmaya ne dersiniz?</p>
                            <p class="text-start fs-5 text-light padding2">İşinizde daha başarılı olmanızı sağlayacak yazılım alanlarında eğitim almak ister misiniz?</p>
                            <p class="text-start fs-5 text-light padding2">Uzman eğitim programlarımız aracılığıyla çalışanlarınızın yazılım geliştirme konusundaki bilgi ve becerilerini geliştirmek ister misiniz?</p><br />
                            <p className=" text-center fs-3"><a href="/education" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Bize Ulaşın</a></p>
                        </Col>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img2} class="img-fluid" alt="..." /></Col>
                    </Row>
                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img3} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Consultancy</h1>
                            <p class="text-start fs-5 text-light padding2">Yazılım danışmanlığı hizmetimizden yararlanarak işletmenizde verimliliği artırmak ve maliyetleri optimize etmek ister misiniz?</p>
                            <p class="text-start fs-5 text-light padding2">İşletmenizin teknolojik potansiyelini en üst düzeye çıkarmak ve rekabet avantajı kazanmak için profesyonel yazılım danışmanlığımızdan yararlanmaya ne dersiniz?</p>
                            <p class="text-start fs-5 text-light padding2">Hangi yazılım sorunlarıyla karşılaşıyorsunuz ve ne tür yardıma ihtiyacınız var?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Bize Ulaşın</a></p>
                        </Col>
                    </Row>
                    <Row>

                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Website</h1>
                            <p class="text-start fs-5 text-light padding2">Web siteleri geliştirerek müşterilerinize daha iyi hizmet vermek ister misiniz?</p>
                            <p class="text-start fs-5 text-light padding2">Müşterilerinizin deneyimini geliştirecek, markanızı ön plana çıkaracak bir web uygulamasına sahip olmak ister misiniz?</p>
                            <p class="text-start fs-5 text-light padding2">İşletmeniz için özelleştirilmiş, kullanıcı dostu ve yenilikçi bir web uygulaması geliştirmeye ne dersiniz?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Bize Ulaşın</a></p>
                        </Col>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img4} class="img-fluid" alt="..." /></Col>
                    </Row>

                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img5} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Uygulamalar</h1>
                            <p class="text-start fs-5 text-light padding2">Want to expand your reach and engage with customers on mobile platforms through a personalized Android application developed by our team?</p>
                            <p class="text-start fs-5 text-light padding2">Ekibimiz tarafından geliştirilen kişiselleştirilmiş bir Android uygulaması aracılığıyla erişiminizi genişletmek ve mobil platformlardaki müşterilerinizle etkileşim kurmak mı istiyorsunuz?</p>
                            <p class="text-start fs-5 text-light padding2">Mobil dünyada yerinizi almak ve kullanıcılarınıza benzersiz bir Android uygulaması sunmak için bizimle çalışmaya ne dersiniz?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Bize Ulaşın</a></p>
                        </Col>
                    </Row>
                    <Row id="ContactUs" className="marginCart1">
                        <Col className="col-12 col-sm-12 col-lg-6">
                            <h1 className="text-center">Bize Ulaşın</h1>
                            <Row className="marginCart1">
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="name@example.com" />
                                        <label for="floatingInput">Email adress</label>
                                    </div>
                                </Col>
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="Davronbek" />
                                        <label for="floatingInput">Adınız</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="Uzbekistan Fergana" />
                                        <label for="floatingInput">Adress</label>
                                    </div>
                                </Col>
                                <Col>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control " id="floatingInput" placeholder="+998 99 000 00 00" />
                                        <label for="floatingInput">Telefon numarası</label>
                                    </div>
                                </Col>
                            </Row>
                            <div class="form-floating mb-3">
                                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>
                                <label for="floatingTextarea2">Yorum</label>
                            </div>
                            <input class="btn btn-primary btnOurTeam" type="submit" value="Submit" />

                        </Col>
                        <Col className="marginCart1 col-12 col-sm-12 col-lg-6">
                            <div class="card text-center border-success">
                                <div class="card-header">
                                    <h5 class="card-title"> Bizimle buluş</h5>
                                </div>
                                <div class="card-body text-start">
                                    <h5 class="card-title">Bizi arayın</h5>
                                    <p class="card-text">+90 534 295 36 67</p>
                                    <h5 class="card-title">Lakasyon</h5>
                                    <p class="card-text">Türkiye-Ankara </p>
                                    <h5 class="card-title">İş saati</h5>
                                    <p class="card-text">+3 09:00-12:00 and 14:00-16:00</p>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row className="marginCart1">
                        <FooterComponent></FooterComponent>
                    </Row>

                </Container>
            </div>
        );
    } else {
        return (
            <div>
                <Container className="marginThero body">
                    <Row className="headerControl">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                        <HeaderComponent></HeaderComponent>
                    </Row>
                    <Row className="hederCon">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                        <HeaderComponentGad></HeaderComponentGad>
                    </Row>
                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img1} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Texnik yordam</h1>
                            <p class="text-start fs-5 text-light padding2">Dasturiy ta'minot sohasida tajribali va samaraliroq bo'lish uchun texnik yordam olishni xohlaysizmi?</p>
                            <p class="text-start fs-5 text-light padding2">Bizning jamoamizning mutaxassis texnik yordami bilan dasturiy ta'minotni ishlab chiqish jarayonini yaxshilamoqchimisiz?</p>
                            <p class="text-start fs-5 text-light padding2">Dasturiy ta'minot bilan bog'liq muammolarni tez va samarali hal qilish uchun texnik yordam ko'rsatish bo'yicha mutaxassis jamoamiz bilan uchrashishga nima deysiz?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Aloqa</a></p>
                        </Col>
                    </Row>
                    <Row>

                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Ta'lim</h1>
                            <p class="text-start fs-5 text-light padding2">Dasturiy ta'minot ko'nikmalaringizni oshirish va martabangizni bir qadam oldinga olib borish uchun mutaxassis trenerlarimiz bilan ishlashga nima deysiz?</p>
                            <p class="text-start fs-5 text-light padding2">Ishingizda muvaffaqiyat qozonishingiz uchun dasturiy ta'minot sohalarida o'qitilishini xohlaysizmi?</p>
                            <p class="text-start fs-5 text-light padding2">Bizning maxsus o'quv dasturlarimiz orqali xodimlaringizning dasturiy ta'minotni ishlab chiqish bo'yicha malaka va bilimlarini oshirishni xohlaysizmi?</p><br />
                            <p className=" text-center fs-3"><a href="/education" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Aloqa</a></p>
                        </Col>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img2} class="img-fluid" alt="..." /></Col>
                    </Row>
                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img3} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Consultancy</h1>
                            <p class="text-start fs-5 text-light padding2">Dasturiy ta'minot bo'yicha maslahat xizmatimizdan foydalanib, biznesingiz samaradorligini oshirish va xarajatlarni optimallashtirishni xohlaysizmi?</p>
                            <p class="text-start fs-5 text-light padding2">Biznesingizning texnologik salohiyatini maksimal darajada oshirish va raqobatbardosh ustunlikka ega bo'lish uchun professional dasturiy ta'minot bo'yicha maslahatimizdan foydalanishga nima deysiz?</p>
                            <p class="text-start fs-5 text-light padding2">Qanday dasturiy ta'minot muammolariga duch kelyapsiz va sizga qanday yordam kerak?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Aloqa</a></p>
                        </Col>
                    </Row>
                    <Row>

                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Website</h1>
                            <p class="text-start fs-5 text-light padding2">Web-saytlarni ishlab chiqish orqali mijozlaringizga yaxshiroq xizmat ko'rsatishni xohlaysizmi?</p>
                            <p class="text-start fs-5 text-light padding2">Mijozlaringiz tajribasini yaxshilaydigan va brendingizni ta'kidlaydigan veb-ilovaga ega bo'lishni xohlaysizmi?</p>
                            <p class="text-start fs-5 text-light padding2">Biznesingiz uchun moslashtirilgan, foydalanuvchilar uchun qulay va innovatsion veb-ilovani ishlab chiqish haqida nima deyish mumkin?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Aloqa</a></p>
                        </Col>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img4} class="img-fluid" alt="..." /></Col>
                    </Row>

                    <Row>
                        <Col className="paddingThero col-12 col-sm-12 col-lg-6"><img src={img5} class="img-fluid" alt="..." /></Col>
                        <Col className="paddingThero bgAboutUsPageCol col-12 col-sm-12 col-lg-6">
                            <h1 className="text-light marginCart1 text-center">Ilovalar</h1>
                            <p class="text-start fs-5 text-light padding2">Jamoamiz tomonidan ishlab chiqilgan shaxsiy Android ilovasi orqali mobil platformalarda mijozlar bilan muloqot doirangizni kengaytirmoqchimisiz?</p>
                            <p class="text-start fs-5 text-light padding2">Android ilovalarini ishlab chiqish orqali mijozlaringizga yaxshiroq xizmat ko'rsatishni xohlaysizmi?</p>
                            <p class="text-start fs-5 text-light padding2">Mobil dunyoda o'z o'rningizni egallash va foydalanuvchilarga noyob Android ilovasini taklif qilish uchun biz bilan ishlashga nima deysiz?</p><br />
                            <p className=" text-center fs-3"><a href="#ContactUs" class="text-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Aloqa</a></p>
                        </Col>
                    </Row>
                    <Row id="ContactUs" className="marginCart1">
                        
                            <Col className="col-12 col-sm-12 col-lg-6">
                            <Form action="https://formspree.io/abdurazzoqovdavronbek3@email.com" method="post">
                                <h1 className="text-center">Aloqaga chiq</h1>
                                <Row className="marginCart1">
                                    <Col>
                                        <div class="form-floating mb-3">
                                            <input type="email" class="form-control " name="_replyto"  onChange={(e) => setformemail(e.target.value)}  placeholder="name@example.com" required />
                                            <label for="_replyto" >E-pochta manzili</label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div class="form-floating mb-3">
                                            <input type="email" name="contact_author" class="form-control "  onChange={(e) => setformeauthor(e.target.value)} placeholder="Davronbek" />
                                            <label for="contact_author">Ismingiz</label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div class="form-floating mb-3">
                                            <input type="email" class="form-control " onChange={(e) => setformmanzil(e.target.value)}  placeholder="Uzbekistan Fergana" />
                                            <label for="floatingInput">Manzil</label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div class="form-floating mb-3">
                                            <input type="email" class="form-control " name="phone_number"  onChange={(e) => setformnumber(e.target.value)} placeholder="+998 99 000 00 00" />
                                            <label for="phone_number">Telefon raqami</label>
                                        </div>
                                    </Col>
                                </Row>
                                <div class="form-floating mb-3">
                                    <textarea class="form-control" name="content" placeholder="Leave a comment here"  onChange={(e) => setformizoh(e.target.value)} ></textarea>
                                    <label for="content">Izohlar</label>
                                </div>
                                <input class="btn btn-primary btnOurTeam" type="submit" onClick={submitEvent} value="Submit" />
                                </Form>
                            </Col>
                        
                        <Col className="marginCart1 col-12 col-sm-12 col-lg-6">
                            <div class="card text-center border-success">
                                <div class="card-header">
                                    <h5 class="card-title"> Biz bilan tanishing</h5>
                                </div>
                                <div class="card-body text-start">
                                    <h5 class="card-title">Bizga qo'ng'iroq qiling</h5>
                                    <p class="card-text">+90 534 295 36 67</p>
                                    <h5 class="card-title">Lakatsiya</h5>
                                    <p class="card-text">Turkiya-Anqara</p>
                                    <h5 class="card-title">Ish vaqti</h5>
                                    <p class="card-text">+3 09:00-12:00 and 14:00-16:00</p>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row className="marginCart1">
                        <FooterComponent></FooterComponent>
                    </Row>

                </Container>
            </div>
        );
    }
}
export default AboutUsPage;