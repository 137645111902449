
import { MainContext } from './Context/MainContext';
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUsPages from "./Pages/AboutUsPages";
import OurEduaction from "./Pages/OurEducation";
function App() {

  const [lan, setLan] = useState("en");

  const [methods, setMethods] = useState({});

  const appendMethods = newMethods => {
    setMethods({
      ...methods,
      ...newMethods
    });
  };

  const data = {
    lan,
    setLan,
    setMethods,
    appendMethods,
    ...methods

  }
  return (
    <MainContext.Provider value={data} >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/AboutUsPage/" element={<AboutUsPages />}/>
          <Route path="/education/" element={<OurEduaction />}/>
        </Routes>
      </BrowserRouter>
    </MainContext.Provider>
  );
}

export default App;
