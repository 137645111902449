import { Col, Container, Row } from "reactstrap";
import { MainContext, useContext } from "../Context/MainContext";

function FooterComponent() {

  const { lan } = useContext(MainContext);

  if (lan === 'en') {
    return (
      <div className="paddingThero" >
        <Container className="footer marginThero " >
          <Row className="rowFooter ">
            <Col className="control MeetOurTeam border border-top-0 border-bottom-0 border-start-0 border-success border-2   ">
              <h3 className="text-light">Tags</h3>
              <button type="button" class="btn btn-primary btnFooter ">Javascript</button>
              <button type="button" class="btn btn-secondary btnFooter">Java</button>
              <button type="button" class="btn btn-success btnFooter">HTML</button>
              <button type="button" class="btn btn-danger btnFooter">React</button>
              <button type="button" class="btn btn-warning btnFooter">Python</button>
              <button type="button" class="btn btn-info btnFooter">Flutter</button>
              <button type="button" class="btn btn-light btnFooter">App developer</button>
              <button type="button" class="btn btn-dark btnFooter">Web developer</button>
            </Col>
            <Col className="MeetOurTeam border border-top-0 border-bottom-0 border-start-0 border-success border-2   col-xs-6 ">
              <ul className="text-light listFooter fontsize">
                <h3 className="text-light aboutUsTextFooter h1Tags">About Us</h3>
                <li> <a href="#WhoAreWe-en" class="text-light liFooter padding2 textAnimBlue">Who are we ?</a></li>
                <li> <a href="#ourTeam-en" class="text-light liFooter padding2 textAnimBlue">Our Team</a></li>
                <li> <a href="/education" class="text-light liFooter padding2 textAnimBlue">Our Education</a></li>
                <li> <a href="#ourAim-en" class="text-light liFooter padding2 textAnimBlue">Our Aims</a> </li>
                <li> <a href="#ourProducts-en" class="text-light liFooter padding2 textAnimBlue">Our products</a></li>

              </ul>
            </Col>
            <Col className="MeetOurTeam  col-xs-6  ">
              <h3 className="text-light folowUsFooter h1Tags">Follow Us</h3>
              <Row className="folowUsFooter rowFooter">
                <Col><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="red" class="bi bi-instagram rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg></Col>
                <Col>
                  <a href="https://github.com/WeStarted02">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="white" class="bi bi-github rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                    </svg>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.linkedin.com/company/westarted/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-linkedin rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                    </svg>
                  </a>
                </Col>
                <Col>
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-telegram rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                  </svg>
                </Col>
                <Col >
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class=" bi bi-facebook rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                  </svg>
                </Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
          <Row className="rowFooter border border-success-subtle border-bottom-0 border-start-0">
            <h6 className="text-light"><a href="https://www.termsfeed.com/live/aab00abd-3172-4b97-800d-67c660b87849" className="link-underline-opacity-0 link-light">@Privacy Policy </a></h6>
          </Row>
        </Container>
      </div>
    );
  } if (lan === 'ru') {
    return (
      <div className="paddingThero" >
        <Container className="footer marginThero " >
          <Row className="rowFooter ">
            <Col className="MeetOurTeam border border-top-0 border-bottom-0 border-start-0 border-success border-2 control">
              <h3 className="text-light">Теги</h3>
              <button type="button" class="btn btn-primary btnFooter ">Javascript</button>
              <button type="button" class="btn btn-secondary btnFooter">Java</button>
              <button type="button" class="btn btn-success btnFooter">HTML</button>
              <button type="button" class="btn btn-danger btnFooter">React</button>
              <button type="button" class="btn btn-warning btnFooter">Python</button>
              <button type="button" class="btn btn-info btnFooter">Flutter</button>
              <button type="button" class="btn btn-light btnFooter">Разработчик приложения</button>
              <button type="button" class="btn btn-dark btnFooter">Bеб-разработчик</button>
            </Col>
            <Col className="MeetOurTeam border border-top-0 border-bottom-0 border-start-0 border-success border-2 col-xs-6">
              <ul className="text-light listFooter fontsize">
                <h3 className="text-light aboutUsTextFooter h1Tags">О нас</h3>
                <li> <a href="#WhoAreWe-ru" class="text-light liFooter padding2 textAnimBlue">Кто мы ?</a></li>
                <li> <a href="#ourTeam-ru" class="text-light liFooter padding2 textAnimBlue">Наша команда</a></li>
                <li> <a href="/education" class="text-light liFooter padding2 textAnimBlue">Наше образование</a></li>
                <li> <a href="#ourAim-ru" class="text-light liFooter padding2 textAnimBlue">Наши цели</a> </li>
                <li> <a href="#ourProducts-ru" class="text-light liFooter padding2 textAnimBlue">Наши продукты</a></li>

              </ul>
            </Col>
            <Col className="MeetOurTeam col-xs-6">
              <h3 className="text-light folowUsFooter h1Tags">Подписывайтесь на нас</h3>
              <Row className="folowUsFooter rowFooter">
                <Col><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="red" class="bi bi-instagram rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg></Col>
                <Col>
                  <a href="https://github.com/WeStarted02">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="white" class="bi bi-github rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                    </svg>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.linkedin.com/company/westarted/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-linkedin rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                    </svg>
                  </a>
                </Col>
                <Col>
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-telegram rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                  </svg>
                </Col>
                <Col >
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class=" bi bi-facebook rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                  </svg>
                </Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
          <Row className="rowFooter border border-success-subtle border-bottom-0 border-start-0">
            <h6 className="text-light"><a href="https://www.termsfeed.com/live/aab00abd-3172-4b97-800d-67c660b87849" className="link-underline-opacity-0 link-light">@Privacy Policy </a></h6>
          </Row>
        </Container>
      </div>
    );
  } if (lan === 'tr') {
    return (
      <div className="paddingThero" >
        <Container className="footer marginThero " >
          <Row className="rowFooter ">
            <Col className="MeetOurTeam border border-top-0 border-bottom-0 border-start-0 border-success border-2 control">
              <h3 className="text-light">Etiketler</h3>
              <button type="button" class="btn btn-primary btnFooter ">Javascript</button>
              <button type="button" class="btn btn-secondary btnFooter">Java</button>
              <button type="button" class="btn btn-success btnFooter">HTML</button>
              <button type="button" class="btn btn-danger btnFooter">React</button>
              <button type="button" class="btn btn-warning btnFooter">Python</button>
              <button type="button" class="btn btn-info btnFooter">Flutter</button>
              <button type="button" class="btn btn-light btnFooter">Uygulama geliştiricisi</button>
              <button type="button" class="btn btn-dark btnFooter">Web geliştiricisi</button>
            </Col>
            <Col className="MeetOurTeam border border-top-0 border-bottom-0 border-start-0 border-success border-2 col-xs-6">
              <ul className="text-light listFooter fontsize">
                <h3 className="text-light aboutUsTextFooter h1Tags">Hakkımızda</h3>
                <li> <a href="#WhoAreWe-tr" class="text-light liFooter padding2 textAnimBlue">Biz kimiz ?</a></li>
                <li> <a href="#ourTeam-tr" class="text-light liFooter padding2 textAnimBlue">Bizim takım</a></li>
                <li> <a href="/education" class="text-light liFooter padding2 textAnimBlue">Eğitimimiz</a></li>
                <li> <a href="#ourAim-tr" class="text-light liFooter padding2 textAnimBlue">Amaçlarımız</a> </li>
                <li> <a href="#ourProducts-tr" class="text-light liFooter padding2 textAnimBlue">Bizim ürünlerimiz</a></li>

              </ul>
            </Col>
            <Col className="MeetOurTeam col-xs-6">
              <h3 className="text-light folowUsFooter h1Tags">Bizi takip edin</h3>
              <Row className="folowUsFooter rowFooter">
                <Col><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="red" class="bi bi-instagram rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg></Col>
                <Col>
                  <a href="https://github.com/WeStarted02">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="white" class="bi bi-github rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                    </svg>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.linkedin.com/company/westarted/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-linkedin rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                    </svg>
                  </a>
                </Col>
                <Col>
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-telegram rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                  </svg>
                </Col>
                <Col >
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class=" bi bi-facebook rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                  </svg>
                </Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
          <Row className="rowFooter border border-success-subtle border-bottom-0 border-start-0">
            <h6 className="text-light"><a href="https://www.termsfeed.com/live/aab00abd-3172-4b97-800d-67c660b87849" className="link-underline-opacity-0 link-light">@Privacy Policy </a></h6>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="paddingThero" >
        <Container className="footer marginThero " >
          <Row className="rowFooter ">
            <Col className="MeetOurTeam border border-top-0 border-bottom-0 border-start-0 border-success border-2 control">
              <h3 className="text-light">Teglar</h3>
              <button type="button" class="btn btn-primary btnFooter ">Javascript</button>
              <button type="button" class="btn btn-secondary btnFooter">Java</button>
              <button type="button" class="btn btn-success btnFooter">HTML</button>
              <button type="button" class="btn btn-danger btnFooter">React</button>
              <button type="button" class="btn btn-warning btnFooter">Python</button>
              <button type="button" class="btn btn-info btnFooter">Flutter</button>
              <button type="button" class="btn btn-light btnFooter">Ilova ishlab chiqaruvchisi</button>
              <button type="button" class="btn btn-dark btnFooter">Web dasturchi</button>
            </Col>
            <Col className="MeetOurTeam border border-top-0 border-bottom-0 border-start-0 border-success border-2 col-xs-6">
              <ul className="text-light listFooter fontsize">
                <h3 className="text-light aboutUsTextFooter h1Tags">Biz haqimizda</h3>
                <li> <a href="#WhoAreWe-uz" class="text-light liFooter padding2 textAnimBlue">Biz kimmiz?</a></li>
                <li> <a href="#ourTeam-uz" class="text-light liFooter padding2 textAnimBlue">Bizning Jamoamiz</a></li>
                <li> <a href="/education" class="text-light liFooter padding2 textAnimBlue">Bizning ta'lim</a></li>
                <li> <a href="#ourAim-uz" class="text-light liFooter padding2 textAnimBlue">Bizning Maqsadlarimiz</a> </li>
                <li> <a href="#ourProducts-uz" class="text-light liFooter padding2 textAnimBlue">Bizning mahsulotlarimiz</a></li>

              </ul>
            </Col>
            <Col className="MeetOurTeam col-xs-6">
              <h3 className="text-light folowUsFooter h1Tags">Bizni kuzating</h3>
              <Row className="folowUsFooter rowFooter">
                <Col><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="red" class="bi bi-instagram rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg></Col>
                <Col>
                  <a href="https://github.com/WeStarted02">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="white" class="bi bi-github rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                    </svg>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.linkedin.com/company/westarted/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-linkedin rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                    </svg>
                  </a>
                </Col>
                <Col>
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-telegram rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                  </svg>
                </Col>
                <Col >
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class=" bi bi-facebook rowFooter socialLogoAnim logosSocial" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                  </svg>
                </Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
          <Row className="rowFooter border border-success-subtle border-bottom-0 border-start-0">
            <h6 className="text-light"><a href="https://www.termsfeed.com/live/aab00abd-3172-4b97-800d-67c660b87849" className="link-underline-opacity-0 link-light">@Privacy Policy </a></h6>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FooterComponent;
