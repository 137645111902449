import { Container, Row } from "reactstrap";
import HeaderNavComponent from './HeaderNavigationComponent'
import { MainContext, useContext } from "../Context/MainContext";
function HeaderComponent() {
  const { lan } = useContext(MainContext);

  if (lan === 'en') {
    return (
      <Container className='headerBack'>
        <Row ><HeaderNavComponent ></HeaderNavComponent></Row>
        <Row>
          <div className="">
            <br />
            <p className="card-text text-center text-light  headerText" >As the leading digital education and technology company in Uzbekistan, we offer you the newest and most innovative solutions. We are here to help you in every area you need and to take you to the highest level. From websites to applications, educational content to consultancy services, we are by your side in every field. You can trust us to help you reach your goals. Contact us to bring your dream digital world to life.</p>
            <br />
          </div>
        </Row>
      </Container>
    );
  }
  if (lan === "tr") {
    return (
      <Container className='headerBack'>
        <Row ><HeaderNavComponent ></HeaderNavComponent></Row>
        <Row>
          <div className="">
            <br />
            <p className="card-text text-center text-light  headerText" >Özbekistan'ın lider dijital eğitim ve teknoloji şirketi olarak sizlere en yeni ve en yenilikçi çözümleri sunuyoruz. İhtiyacınız olan her alanda size yardımcı olmak ve sizi en üst seviyeye taşımak için buradayız. Web sitelerinden uygulamalara, eğitim içeriklerinden danışmanlık hizmetlerine kadar her alanda yanınızdayız. Hedeflerinize ulaşmanıza yardımcı olacağımıza güvenebilirsiniz. Hayalinizdeki dijital dünyayı hayata geçirmek için bizimle iletişime geçin.</p>
            <br />
          </div>
        </Row>
      </Container>
    );
  } if (lan === "ru") {
    return (
      <Container className='headerBack'>
        <Row ><HeaderNavComponent ></HeaderNavComponent></Row>
        <Row>
          <div className="">
            <br />
            <p className="card-text text-center text-light  headerText" >Как ведущая компания в области цифрового образования и технологий в Узбекистане, мы предлагаем вам новейшие и самые инновационные решения. Мы здесь, чтобы помочь вам во всех необходимых вам областях и вывести вас на самый высокий уровень. От веб-сайтов до приложений, от образовательного контента до консультационных услуг — мы рядом с вами во всех областях. Вы можете доверить нам помощь в достижении ваших целей. Свяжитесь с нами, чтобы воплотить в жизнь цифровой мир вашей мечты.</p>
            <br />
          </div>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container className='headerBack'>
        <Row ><HeaderNavComponent ></HeaderNavComponent></Row>
        <Row>
          <div className="">
            <br />
            <p className="card-text text-center text-light  headerText" >O‘zbekistondagi yetakchi raqamli ta’lim va texnologiya kompaniyasi sifatida biz sizga eng yangi va innovatsion yechimlarni taklif etamiz. Biz sizga kerak bo'lgan har bir sohada yordam berish va sizni eng yuqori darajaga olib chiqish uchun shu yerdamiz. Veb-saytlardan tortib ilovalargacha, ta'lim mazmunigacha konsalting xizmatlarigacha biz har sohada siz bilan birgamiz. Maqsadlaringizga erishishda yordam berishimizga ishonishingiz mumkin. O'zingizning orzuingizdagi raqamli dunyoni amalga oshirish uchun biz bilan bog'laning.</p>
            <br />
          </div>
        </Row>
      </Container>
    );
  }


}

export default HeaderComponent;
