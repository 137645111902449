import HeaderNavComponent from "../Components/HeaderNavigationComponent";
import HeaderNavComponentGad from "../GadgetComponents/HeaderNavigationCompGad";
import { Container, Row, Col } from "reactstrap";
import FooterComponent from "../Components/FooterComponent";
import happyWoman from "../backgrounds/happyWoman.jpg";
import img1 from "../backgrounds/student1.jpeg";
import img2 from "../backgrounds/student2.jpeg";
import img3 from "../backgrounds/student3.jpeg";
import { MainContext, useContext } from "../Context/MainContext";
function OurEduaction() {
const {lan} = useContext(MainContext);


if(lan==="en"){
    return (
        <div>
            <Container className="marginThero ">
                <Row className="headerControl"> {/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderNavComponent></HeaderNavComponent>
                </Row>
                <Row className="hederCon">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderNavComponentGad></HeaderNavComponentGad>
                </Row>
                <Row className="bg-dark">
                    <Col className="col-12 col-sm-12 col-lg-6">
                        <h1 className="eduFontColor text-center productsNextBtnPosition ">Online Course</h1>
                        <p className="text-light folowUsFooter fs-5">Online courses facilitate access to information and make learning possible from anywhere. Whether you are at home or on the road, learning new skills with these courses is at your fingertips. Learn shortcuts that make your life easier, such as Ctrl+Z, improve yourself with online courses!</p>
                    </Col>
                    <Col className="paddingThero col-12 col-sm-12 col-lg-6">
                        <img src={happyWoman} className="img-fluid "></img>
                    </Col>
                </Row>
                <Row className="eduRow2Bg ">
                    <h3 className="text-light text-center margin30">​Start learning from the world’s bests</h3>
                    <Row className="margin30">
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>01</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>
                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>02</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>03</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                    </Row>
                    <Row className="margin30 marginBottom30">
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>04</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>05</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>06</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                    </Row>
                </Row>
                <Row className="margin30 marginBottom30">
                    <h1 className="eduFontColor text-center">Free online Courses</h1>
                    <Row className="margin30">
                        <Col>
                            <div class="card" >
                                <img src={img1} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">HTML course</h5>
                                    <p class="card-text">With free courses, get unlimited access to knowledge and take your career to the top at no cost!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="card" >
                                <img src={img3} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">Java Course</h5>
                                    <p class="card-text">With free courses, get unlimited access to knowledge and take your career to the top at no cost!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="card" >
                                <img src={img2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">IELTS Course</h5>
                                    <p class="card-text">With free courses, get unlimited access to knowledge and take your career to the top at no cost!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>
                <Row id="ContactUs_" className="marginCart1 eduRow2Bg">
                    <Col className="marginCart1 col-12 col-sm-12 col-lg-6">
                        <div class="card text-center border-success">
                            <div class="card-header">
                                <h5 class="card-title"> Meet us</h5>
                            </div>
                            <div class="card-body text-start">
                                <h5 class="card-title">Call us</h5>
                                <p class="card-text">+90 534 295 36 67</p>
                                <h5 class="card-title">Lacation</h5>
                                <p class="card-text">Turkey-Ankara</p>
                                <h5 class="card-title">Business hour</h5>
                                <p class="card-text">+3 09:00-12:00 and 14:00-16:00</p>
                            </div>

                        </div>
                    </Col>
                    <Col className="col-12 col-sm-12 col-lg-6">
                        <h1 className="text-center text-light margin30 marginBottom30">Contact us</h1>
                        <Row className="marginCart1">
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="name@example.com" />
                                    <label for="floatingInput">Email address</label>
                                </div>
                            </Col>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="Davronbek" />
                                    <label for="floatingInput">Your name</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="Uzbekistan Fergana" />
                                    <label for="floatingInput">Adress</label>
                                </div>
                            </Col>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="+998 99 000 00 00" />
                                    <label for="floatingInput">Phone number</label>
                                </div>
                            </Col>
                        </Row>
                        <div class="form-floating mb-3">
                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>
                            <label for="floatingTextarea2">Comments</label>
                        </div>
                        <input class="btn btn-primary btnOurTeam margin30 marginBottom30" type="submit" value="Submit" />

                    </Col>

                </Row>
                <Row>
                    <FooterComponent></FooterComponent>
                </Row>
            </Container>
        </div>
    );
}if(lan==="ru"){
    return (
        <div>
            <Container className="marginThero ">
                <Row className="headerControl"> {/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderNavComponent></HeaderNavComponent>
                </Row>
                <Row className="hederCon">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderNavComponentGad></HeaderNavComponentGad>
                </Row>
                <Row className="bg-dark">
                    <Col className="col-12 col-sm-12 col-lg-6">
                        <h1 className="eduFontColor text-center productsNextBtnPosition ">Онлайн-курс</h1>
                        <p className="text-light folowUsFooter fs-5">Онлайн-курсы облегчают доступ к информации и делают обучение возможным из любой точки мира. Независимо от того, находитесь ли вы дома или в дороге, приобретение новых навыков с помощью этих курсов всегда у вас под рукой. Изучите сочетания клавиш, которые облегчат вашу жизнь, например Ctrl+Z, и улучшите себя с помощью онлайн-курсов!</p>
                    </Col>
                    <Col className="paddingThero col-12 col-sm-12 col-lg-6">
                        <img src={happyWoman} className="img-fluid "></img>
                    </Col>
                </Row>
                <Row className="eduRow2Bg ">
                    <h3 className="text-light text-center margin30">Начните учиться у лучших в мире</h3>
                    <Row className="margin30">
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>01</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Курс фронтенд-разработчика</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>
                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>02</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>03</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                    </Row>
                    <Row className="margin30 marginBottom30">
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>04</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>05</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>06</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                    </Row>
                </Row>
                <Row className="margin30 marginBottom30">
                    <h1 className="eduFontColor text-center">Бесплатные онлайн-курсы</h1>
                    <Row className="margin30">
                        <Col>
                            <div class="card" >
                                <img src={img1} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">Курс HTML </h5>
                                    <p class="card-text">Благодаря бесплатным курсам получите неограниченный доступ к знаниям и поднимите свою карьеру на вершину бесплатно!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="card" >
                                <img src={img3} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">Курс Java</h5>
                                    <p class="card-text">Благодаря бесплатным курсам получите неограниченный доступ к знаниям и поднимите свою карьеру на вершину бесплатно!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="card" >
                                <img src={img2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">IELTS Course</h5>
                                    <p class="card-text">Благодаря бесплатным курсам получите неограниченный доступ к знаниям и поднимите свою карьеру на вершину бесплатно!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>
                <Row id="ContactUs_" className="marginCart1 eduRow2Bg">
                    <Col className="marginCart1 col-12 col-sm-12 col-lg-6">
                        <div class="card text-center border-success">
                            <div class="card-header">
                                <h5 class="card-title"> Встреть нас</h5>
                            </div>
                            <div class="card-body text-start">
                                <h5 class="card-title">Позвоните нам</h5>
                                <p class="card-text">+90 534 295 36 67</p>
                                <h5 class="card-title">Развязка</h5>
                                <p class="card-text">Турция-Анкара</p>
                                <h5 class="card-title">Рабочий час</h5>
                                <p class="card-text">+3 09:00-12:00 and 14:00-16:00</p>
                            </div>

                        </div>
                    </Col>
                    <Col className="col-12 col-sm-12 col-lg-6">
                        <h1 className="text-center text-light margin30 marginBottom30">Связаться с нами</h1>
                        <Row className="marginCart1">
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="name@example.com" />
                                    <label for="floatingInput"> Электронная почта</label>
                                </div>
                            </Col>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="Davronbek" />
                                    <label for="floatingInput">Ваше имя</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="Uzbekistan Fergana" />
                                    <label for="floatingInput">Адрес</label>
                                </div>
                            </Col>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="+998 99 000 00 00" />
                                    <label for="floatingInput">Номер телефона</label>
                                </div>
                            </Col>
                        </Row>
                        <div class="form-floating mb-3">
                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>
                            <label for="floatingTextarea2">Комментарии</label>
                        </div>
                        <input class="btn btn-primary btnOurTeam margin30 marginBottom30" type="submit" value="Submit" />

                    </Col>

                </Row>
                <Row>
                    <FooterComponent></FooterComponent>
                </Row>
            </Container>
        </div>
    );
}if(lan==="tr"){
    return (
        <div>
            <Container className="marginThero ">
                <Row className="headerControl"> {/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderNavComponent></HeaderNavComponent>
                </Row>
                <Row className="hederCon">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderNavComponentGad></HeaderNavComponentGad>
                </Row>
                <Row className="bg-dark">
                    <Col className="col-12 col-sm-12 col-lg-6">
                        <h1 className="eduFontColor text-center productsNextBtnPosition ">Çevrimiçi Kurs</h1>
                        <p className="text-light folowUsFooter fs-5">Çevrimiçi kurslar bilgiye erişimi kolaylaştırır ve her yerden öğrenmeyi mümkün kılar. İster evde ister yolda olun, bu kurslarla yeni beceriler öğrenmek parmaklarınızın ucunda. Ctrl+Z gibi hayatınızı kolaylaştıracak kısayolları öğrenin, online kurslarla kendinizi geliştirin!</p>
                    </Col>
                    <Col className="paddingThero col-12 col-sm-12 col-lg-6">
                        <img src={happyWoman} className="img-fluid "></img>
                    </Col>
                </Row>
                <Row className="eduRow2Bg ">
                    <h3 className="text-light text-center margin30">Dünyanın en iyilerinden öğrenmeye başlayın</h3>
                    <Row className="margin30">
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>01</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>
                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>02</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>03</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                    </Row>
                    <Row className="margin30 marginBottom30">
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>04</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>05</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>06</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                    </Row>
                </Row>
                <Row className="margin30 marginBottom30">
                    <h1 className="eduFontColor text-center">Ücretsiz çevrimiçi Kurslar</h1>
                    <Row className="margin30">
                        <Col>
                            <div class="card" >
                                <img src={img1} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">HTML course</h5>
                                    <p class="card-text">Ücretsiz kurslarla bilgiye sınırsız erişim sağlayın ve kariyerinizi hiçbir ücret ödemeden zirveye taşıyın!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="card" >
                                <img src={img3} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">Java Course</h5>
                                    <p class="card-text">Ücretsiz kurslarla bilgiye sınırsız erişim sağlayın ve kariyerinizi hiçbir ücret ödemeden zirveye taşıyın!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="card" >
                                <img src={img2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">IELTS Course</h5>
                                    <p class="card-text">Ücretsiz kurslarla bilgiye sınırsız erişim sağlayın ve kariyerinizi hiçbir ücret ödemeden zirveye taşıyın!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>
                <Row id="ContactUs_" className="marginCart1 eduRow2Bg">
                    <Col className="marginCart1 col-12 col-sm-12 col-lg-6">
                        <div class="card text-center border-success">
                            <div class="card-header">
                                <h5 class="card-title"> Bizimle buluş</h5>
                            </div>
                            <div class="card-body text-start">
                                <h5 class="card-title">Bizi arayın</h5>
                                <p class="card-text">+90 534 295 36 67</p>
                                <h5 class="card-title">Lakasyon</h5>
                                <p class="card-text">Türkiye-Ankara</p>
                                <h5 class="card-title">İş saati</h5>
                                <p class="card-text">+3 09:00-12:00 and 14:00-16:00</p>
                            </div>

                        </div>
                    </Col>
                    <Col className="col-12 col-sm-12 col-lg-6">
                        <h1 className="text-center text-light margin30 marginBottom30">Bize Ulaşın</h1>
                        <Row className="marginCart1">
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="name@example.com" />
                                    <label for="floatingInput">E-posta adresi</label>
                                </div>
                            </Col>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="Davronbek" />
                                    <label for="floatingInput">Adınız</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="Uzbekistan Fergana" />
                                    <label for="floatingInput">Adres</label>
                                </div>
                            </Col>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="+998 99 000 00 00" />
                                    <label for="floatingInput">Telefon numarası</label>
                                </div>
                            </Col>
                        </Row>
                        <div class="form-floating mb-3">
                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>
                            <label for="floatingTextarea2">Yorumlar</label>
                        </div>
                        <input class="btn btn-primary btnOurTeam margin30 marginBottom30" type="submit" value="Submit" />

                    </Col>

                </Row>
                <Row>
                    <FooterComponent></FooterComponent>
                </Row>
            </Container>
        </div>
    );
}else{
    return (
        <div>
            <Container className="marginThero ">
                <Row className="headerControl"> {/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderNavComponent></HeaderNavComponent>
                </Row>
                <Row className="hederCon">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderNavComponentGad></HeaderNavComponentGad>
                </Row>
                <Row className="bg-dark">
                    <Col className="col-12 col-sm-12 col-lg-6">
                        <h1 className="eduFontColor text-center productsNextBtnPosition ">Onlayn Kurs</h1>
                        <p className="text-light folowUsFooter fs-5">Onlayn kurslar ma'lumotlarga kirishni osonlashtiradi va istalgan joydan o'rganish imkonini beradi. Siz uyda yoki yo'lda bo'lasizmi, ushbu kurslar bilan yangi ko'nikmalarni o'rganish sizning qo'lingizda. Ctrl+Z kabi hayotingizni osonlashtiradigan yorliqlarni o'rganing, onlayn kurslar orqali o'zingizni yaxshilang!</p>
                    </Col>
                    <Col className="paddingThero col-12 col-sm-12 col-lg-6">
                        <img src={happyWoman} className="img-fluid "></img>
                    </Col>
                </Row>
                <Row className="eduRow2Bg ">
                    <h3 className="text-light text-center margin30">Dunyoning eng yaxshilaridan o'rganishni boshlang</h3>
                    <Row className="margin30">
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>01</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>
                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>02</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>03</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                    </Row>
                    <Row className="margin30 marginBottom30">
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>04</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>05</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                        <Col>
                            <p className="fs-1 text-light d-inline padding2"><b>06</b></p>
                            <p className="text-light fs-5 d-inline padding2"><b>Frontend developer Course</b></p>
                            <p className="text-light padding2 fst-italic rowFooter">blas bla bla blab blblas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab bla blas bla bla blab blaa</p>

                        </Col>
                    </Row>
                </Row>
                <Row className="margin30 marginBottom30">
                    <h1 className="eduFontColor text-center">Bepul onlayn kurslar</h1>
                    <Row className="margin30">
                        <Col>
                            <div class="card" >
                                <img src={img1} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">HTML course</h5>
                                    <p class="card-text">Bepul kurslar bilan bilimlarga cheksiz kirish huquqiga ega bo'ling va hech qanday to'lovlarsiz martabangizni cho'qqiga olib chiqing!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="card" >
                                <img src={img3} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">Java Course</h5>
                                    <p class="card-text">Bepul kurslar bilan bilimlarga cheksiz kirish huquqiga ega bo'ling va hech qanday to'lovlarsiz martabangizni cho'qqiga olib chiqing!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="card" >
                                <img src={img2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title eduFontColor">IELTS Course</h5>
                                    <p class="card-text">Bepul kurslar bilan bilimlarga cheksiz kirish huquqiga ega bo'ling va hech qanday to'lovlarsiz martabangizni cho'qqiga olib chiqing!</p>
                                    <a href="#" class="link-underline-danger link-offset-3">More</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>
                <Row id="ContactUs_" className="marginCart1 eduRow2Bg">
                    <Col className="marginCart1 col-12 col-sm-12 col-lg-6">
                        <div class="card text-center border-success">
                            <div class="card-header">
                                <h5 class="card-title"> Biz bilan tanishing</h5>
                            </div>
                            <div class="card-body text-start">
                                <h5 class="card-title">Bizga qo'ng'iroq qiling</h5>
                                <p class="card-text">+90 534 295 36 67</p>
                                <h5 class="card-title">Lakatsiya</h5>
                                <p class="card-text">Turkiya-Anqara</p>
                                <h5 class="card-title">Ish vaqti</h5>
                                <p class="card-text">+3 09:00-12:00 and 14:00-16:00</p>
                            </div>

                        </div>
                    </Col>
                    <Col className="col-12 col-sm-12 col-lg-6">
                        <h1 className="text-center text-light margin30 marginBottom30">Biz bilan bog'lanish</h1>
                        <Row className="marginCart1">
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="name@example.com" />
                                    <label for="floatingInput">E-pochta manzili</label>
                                </div>
                            </Col>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="Davronbek" />
                                    <label for="floatingInput">Ismingiz</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="Uzbekistan Fergana" />
                                    <label for="floatingInput">Manzil</label>
                                </div>
                            </Col>
                            <Col>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control " id="floatingInput" placeholder="+998 99 000 00 00" />
                                    <label for="floatingInput">Telefon raqami</label>
                                </div>
                            </Col>
                        </Row>
                        <div class="form-floating mb-3">
                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>
                            <label for="floatingTextarea2">Izohlar</label>
                        </div>
                        <input class="btn btn-primary btnOurTeam margin30 marginBottom30" type="submit" value="Submit" />

                    </Col>

                </Row>
                <Row>
                    <FooterComponent></FooterComponent>
                </Row>
            </Container>
        </div>
    );
}
}
export default OurEduaction;