import {  Row, Container } from "reactstrap";
import AboutUsComponent from '../Components/AboutUsComponent';
import FooterComponent from '../Components/FooterComponent';
import OurTeamComponent from '../Components/OurTeamComponent';
import ProductsComponent from '../Components/ProductsComponent';
import HeaderComponent from '../Components/HeaderComponent';

import HeaderComponentGad from '../GadgetComponents/HeaderComGad';

function Home() {
    return (
        <div >
            <Container className="marginThero body">
                <Row className="headerControl">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderComponent></HeaderComponent>
                </Row>
                <Row className="hederCon">{/* Header Control orqali web sayfamiznidagi navigitoni ko'rinish shaklini to'g'irladik  */}
                    <HeaderComponentGad></HeaderComponentGad>
                </Row>
                <Row className="marginCart1">
                    <ProductsComponent></ProductsComponent>
                </Row>
                <Row className="marginCart1">
                    <AboutUsComponent></AboutUsComponent>
                </Row>
                <Row className="marginCart1">
                    <OurTeamComponent></OurTeamComponent>
                </Row>

                <Row className="marginCart1">
                    <FooterComponent></FooterComponent>
                </Row>
            </Container>
        </div>
    );
};

export default Home;