import { Container, Row, Col } from "reactstrap";
import {MainContext, useContext} from "../Context/MainContext";
function AboutUsComponent() {

const {lan} = useContext(MainContext);


if(lan === "en"){
  return (
    <div id="AboutUs-en">
      <Container className="marginThero paddingThero">
        <Row className="row1">
          <Row className="row2 "></Row>
          
          <Col className="padding2 col-xs-6 col-md-3 col-6" id="WhoAreWe-en">
            <div class="card cardAnim2" >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-bar-chart-steps imgAboutUs" viewBox="0 0 16 16">
                <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
              </svg>
              <div class="card-body">
                <h5 class="card-title text-center h1Tags">Who are we ?</h5>
                <p class="card-text fontsize pHeight ">With innovative ideas, limitless creativity and a passion for technology, <b className="text-dark">&lt;WeStarted&gt;</b> is a team of the brightest developers, most creative designers and most inspiring teachers in the industry.</p>
                <a href="#" class="btn btn-primary">More</a>
              </div>
            </div>
          </Col>
          <Col className="padding2 marginCart1 col-md-3 col-xs-6 col-6">
            <div class="card cardAnim2" >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-person-vcard-fill imgAboutUs" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5M9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8m1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5m-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96q.04-.245.04-.5M7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
              </svg>
              <div class="card-body">
                <h5 class="card-title text-center h1Tags">Our Team</h5>
                <p class="card-text fontsize pHeight">We are a startup equipped with a team spirit at the intersection of technology and creativity, aiming to make a difference in the industry.</p>
                <a href="#ourTeam-en" class="btn btn-primary">More</a>
              </div>
            </div>
          </Col>
          <Col className="padding2 col-xs-6 col-md-3 col-6" id="ourEduaction-en">
            <div class="card cardAnim2" >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="imgAboutUs bi bi-backpack4-fill" viewBox="0 0 16 16">
                <path d="M8 0a2 2 0 0 0-2 2H3.5a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h4v.5a.5.5 0 0 0 1 0V7h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H10a2 2 0 0 0-2-2m1 2a1 1 0 0 0-2 0zm-4 9v2h6v-2h-1v.5a.5.5 0 0 1-1 0V11z" />
                <path d="M14 7.599A3 3 0 0 1 12.5 8H9.415a1.5 1.5 0 0 1-2.83 0H3.5A3 3 0 0 1 2 7.599V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
              </svg>
              <div class="card-body">
                <h5 class="card-title text-center h1Tags ">Our Education</h5>
                <p class="card-text fontsize pHeight">We believe in sharing information and in this way, we provide training on technology and software with our expert teachers.</p>
                <a href="/education" class="btn btn-primary">More</a>
              </div>
            </div>
          </Col>
          <Col className="padding2 marginCart1 col-xs-6 col-md-3 col-6" id="ourAim-en">
            <div class="card cardAnim2" >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-bar-chart-steps imgAboutUs" viewBox="0 0 16 16">
                <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
              </svg>
              <div class="card-body">
                <h5 class="card-title text-center h1Tags">Our Aims</h5>
                <p class="card-text fontsize pHeight">Our aim is to leave our mark in the digital world by providing quality and effective solutions specific to the needs of each of our customers.</p>
                <a href="#" class="btn btn-primary">More</a>
              </div>
            </div>
          </Col>
         
        </Row>
      </Container>
    </div>
  );
}if(lan === "ru"){
  return (
    <div id="AboutUs-ru">
      <Container className="marginThero paddingThero">
        <Row className="row1">
          <Row className="row2"></Row>
          
          <Col className="padding2 col-xs-6 col-md-3 col-6" id="WhoAreWe-ru">
            <div class="card cardAnim2" >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-bar-chart-steps imgAboutUs" viewBox="0 0 16 16">
                <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
              </svg>
              <div class="card-body">
                <h5 class="card-title text-center">Кто мы ?</h5>
                <p class="card-text fontsize pHeight">Обладая инновационными идеями, безграничным творчеством и страстью к технологиям, <b className="text-dark">&lt;WeStarted&gt;</b> — это команда самых ярких разработчиков, самых креативных дизайнеров и самых вдохновляющих преподавателей в отрасли.</p>
                <a href="#" class="btn btn-primary">Более</a>
              </div>
            </div>
          </Col>
          <Col className="padding2 marginCart1 col-xs-6 col-md-3 col-6">
            <div class="card cardAnim2" >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-person-vcard-fill imgAboutUs" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5M9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8m1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5m-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96q.04-.245.04-.5M7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
              </svg>
              <div class="card-body">
                <h5 class="card-title text-center">Наша команда</h5>
                <p class="card-text fontsize pHeight">Мы — стартап, обладающий командным духом на стыке технологий и творчества, стремящийся изменить ситуацию в отрасли.</p>
                <a href="#" class="btn btn-primary">Более</a>
              </div>
            </div>
          </Col>
          <Col className="padding2 col-xs-6 col-md-3 col-6">
            <div class="card cardAnim2" id="ourEduaction-ru">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="imgAboutUs bi bi-backpack4-fill" viewBox="0 0 16 16">
                <path d="M8 0a2 2 0 0 0-2 2H3.5a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h4v.5a.5.5 0 0 0 1 0V7h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H10a2 2 0 0 0-2-2m1 2a1 1 0 0 0-2 0zm-4 9v2h6v-2h-1v.5a.5.5 0 0 1-1 0V11z" />
                <path d="M14 7.599A3 3 0 0 1 12.5 8H9.415a1.5 1.5 0 0 1-2.83 0H3.5A3 3 0 0 1 2 7.599V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
              </svg>
              <div class="card-body">
                <h5 class="card-title text-center">Наше образование</h5>
                <p class="card-text fontsize pHeight">Мы верим в обмен информацией и поэтому проводим обучение технологиям и программному обеспечению с нашими опытными преподавателями.</p>
                <a href="/education" class="btn btn-primary">Более</a>
              </div>
            </div>
          </Col>
          <Col className="padding2 marginCart1 col-xs-6 col-md-3 col-6" id="ourAim-ru">
            <div class="card cardAnim2" >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-bar-chart-steps imgAboutUs" viewBox="0 0 16 16">
                <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
              </svg>
              <div class="card-body">
                <h5 class="card-title text-center">Наши цели</h5>
                <p class="card-text fontsize pHeight">Наша цель — оставить свой след в цифровом мире, предоставляя качественные и эффективные решения, соответствующие потребностям каждого из наших клиентов.</p>
                <a href="#" class="btn btn-primary">Более</a>
              </div>
            </div>
          </Col>
         
        </Row>
      </Container>
    </div>
  );
}if(lan === "tr"){
    return (
      <div id="AboutUs-tr">
        <Container className="marginThero paddingThero">
          <Row className="row1">
            <Row className="row2"></Row>
            
            <Col className="padding2 col-xs-6 col-md-3 col-6" id="WhoAreWe-tr">
              <div class="card cardAnim2" >
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-bar-chart-steps imgAboutUs" viewBox="0 0 16 16">
                  <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                </svg>
                <div class="card-body">
                  <h5 class="card-title text-center">Biz Kimiz ?</h5>
                  <p class="card-text fontsize pHeight">Yenilikçi düşünceler, sınırsız yaratıcılık ve teknolojiye olan tutkumuzla, <b className="text-dark">&lt;WeStarted&gt;</b> olarak, sektördeki en parlak yazılımcılar, en yaratıcı tasarımcılar ve en ilham verici öğretmenlerden oluşan bir ekibiz.</p>
                  <a href="#" class="btn btn-primary">Daha</a>
                </div>
              </div>
            </Col>
            <Col className="padding2 marginCart1 col-xs-6 col-md-3 col-6">
              <div class="card cardAnim2" >
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-person-vcard-fill imgAboutUs" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5M9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8m1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5m-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96q.04-.245.04-.5M7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                </svg>
                <div class="card-body">
                  <h5 class="card-title text-center">Bizim takım</h5>
                  <p class="card-text fontsize pHeight">Biz teknoloji ve yaratıcılığın kesişim noktasında yer alan bir ekip ruhuyla donanmış, sektörde fark yaratmayı hedefleyen bir startup’tır.</p>
                  <a href="#" class="btn btn-primary">Daha</a>
                </div>
              </div>
            </Col>
            <Col className="padding2 col-xs-6 col-md-3 col-6" id="ourEduaction-tr">
              <div class="card cardAnim2" >
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="imgAboutUs bi bi-backpack4-fill" viewBox="0 0 16 16">
                  <path d="M8 0a2 2 0 0 0-2 2H3.5a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h4v.5a.5.5 0 0 0 1 0V7h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H10a2 2 0 0 0-2-2m1 2a1 1 0 0 0-2 0zm-4 9v2h6v-2h-1v.5a.5.5 0 0 1-1 0V11z" />
                  <path d="M14 7.599A3 3 0 0 1 12.5 8H9.415a1.5 1.5 0 0 1-2.83 0H3.5A3 3 0 0 1 2 7.599V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                </svg>
                <div class="card-body">
                  <h5 class="card-title text-center">Eğitimimiz</h5>
                  <p class="card-text fontsize pHeight"> Bilgi paylaşımına inanıyoruz ve bu yolda, alanında uzman öğretmenlerimizle birlikte, teknoloji ve yazılım konularında eğitimler veriyoruz.</p>
                  <a href="/education" class="btn btn-primary">Daha</a>
                </div>
              </div>
            </Col>
            <Col className="padding2 marginCart1 col-xs-6 col-md-3 col-6" id="ourAim-tr">
              <div class="card cardAnim2" >
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-bar-chart-steps imgAboutUs" viewBox="0 0 16 16">
                  <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                </svg>
                <div class="card-body">
                  <h5 class="card-title text-center">Amaçlarımız</h5>
                  <p class="card-text fontsize pHeight">Amacımız, her bir müşterimizin ihtiyaçlarına özel, kaliteli ve etkili çözümler sunarak, dijital dünyada iz bırakmaktır.</p>
                  <a href="#" class="btn btn-primary">Daha</a>
                </div>
              </div>
            </Col>
           
          </Row>
        </Container>
      </div>
    );
  }else{
      return (
        <div id="AboutUs-uz">
          <Container className="marginThero paddingThero">
            <Row className="row1">
              <Row className="row2"></Row>
              
              <Col className="padding2 col-xs-6 col-md-3 col-6" id="WhoAreWe-uz">
                <div class="card cardAnim2" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-bar-chart-steps imgAboutUs" viewBox="0 0 16 16">
                    <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                  </svg>
                  <div class="card-body">
                    <h5 class="card-title text-center">Biz kimmiz?</h5>
                    <p class="card-text fontsize pHeight">Innovatsion g'oyalar, cheksiz ijodkorlik va texnologiyaga ishtiyoq bilan <b className="text-dark">&lt;WeStarted&gt;</b> - bu sohadagi eng yorqin ishlab chiquvchilar, eng ijodiy dizaynerlar va eng ilhomlantiruvchi o'qituvchilar jamoasi.</p>
                    <a href="#" class="btn btn-primary">Ko'proq</a>
                  </div>
                </div>
              </Col>
              <Col className="padding2 marginCart1 col-xs-6 col-md-3 col-6">
                <div class="card cardAnim2" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-person-vcard-fill imgAboutUs" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5M9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8m1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5m-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96q.04-.245.04-.5M7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                  </svg>
                  <div class="card-body">
                    <h5 class="card-title text-center">Bizning Jamoamiz</h5>
                    <p class="card-text fontsize pHeight">Biz texnologiya va ijodkorlik chorrahasida jamoaviy ruh bilan jihozlangan startapmiz va sanoatda o'zgarishlarni amalga oshirishni maqsad qilganmiz.</p>
                    <a href="#" class="btn btn-primary">Ko'proq</a>
                  </div>
                </div>
              </Col>
              <Col className="padding2 col-xs-6 col-md-3 col-6">
                <div class="card cardAnim2" id="ourEduaction-uz">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="imgAboutUs bi bi-backpack4-fill" viewBox="0 0 16 16">
                    <path d="M8 0a2 2 0 0 0-2 2H3.5a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h4v.5a.5.5 0 0 0 1 0V7h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H10a2 2 0 0 0-2-2m1 2a1 1 0 0 0-2 0zm-4 9v2h6v-2h-1v.5a.5.5 0 0 1-1 0V11z" />
                    <path d="M14 7.599A3 3 0 0 1 12.5 8H9.415a1.5 1.5 0 0 1-2.83 0H3.5A3 3 0 0 1 2 7.599V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                  </svg>
                  <div class="card-body">
                    <h5 class="card-title text-center h1Tags">Bizning ta'lim</h5>
                    <p class="card-text fontsize pHeight">Biz ma'lumot almashishga ishonamiz va shu yo'l bilan biz tajribali o'qituvchilarimiz bilan texnologiya va dasturiy ta'minot bo'yicha treninglar o'tkazamiz.</p>
                    <a href="/education" class="btn btn-primary">Ko'proq</a>
                  </div>
                </div>
              </Col>
              <Col className="padding2 marginCart1 col-xs-6 col-md-3 col-6" id="ourAim-uz">
                <div class="card cardAnim2" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="blue" class="bi bi-bar-chart-steps imgAboutUs" viewBox="0 0 16 16">
                    <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                  </svg>
                  <div class="card-body">
                    <h5 class="card-title text-center">Bizning Maqsadlarimiz</h5>
                    <p class="card-text fontsize pHeight">Bizning maqsadimiz - har bir mijozimizning ehtiyojlariga xos sifatli va samarali echimlarni taqdim etish orqali raqamli dunyoda o'z izimizni qoldirishdir.</p>
                    <a href="#" class="btn btn-primary">Ko'proq</a>
                  </div>
                </div>
              </Col>
             
            </Row>
          </Container>
        </div>
      );
}
}
export default AboutUsComponent;
